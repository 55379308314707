<template>
  <Component
    :is="`blueprint-${entry.blueprint?.replaceAll('_', '-')}`"
    v-if="entry"
    :page="entry"
  />
</template>

<script setup lang="ts">
const { locale } = useI18n()
const entryInjection = inject(EntryInjectionKey)!

const entry = useIsInLivePreview() ? entryInjection : ref(unref(entryInjection))

// META TAGS
await useHead({
  ...useGenerateHead(entry.value, locale.value),
})
</script>
